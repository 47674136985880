import React from "react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";

const ServiceHeader = () => {
  return (
    <>
      <Wrapper>
        <Banner>
          <StaticImage
            src="../../../images/service/service_top.webp"
            alt="service header image"
            layout="fullWidth"
            style={{ position: "absolute", height: "100%", width: "100%" }}
          />
          <Heading>
            S<Underline>ERVIC</Underline>E
          </Heading>
        </Banner>
      </Wrapper>
      <SubHeading>
      iTipsでは、日本の製造業および建設業がもつ技術、
      <br />
      そして職人一人ひとりが持つ匠の技能のグローバル展開を通じて、
      <br />
      世界の「モノづくり」、「ヒトづくり」をサポートします。
      </SubHeading>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Banner = styled.div`
  width: 100vw;
  height: 70vh;
  position: relative;
`;
const Heading = styled.div`
  position: relative;
  font-family: "Noto Sans", sans-serif;
  top: 95%;
  text-align: center;
  font-size: 4.1rem;
  font-weight: 700;
  color: #f2f2f2;
`;
const Underline = styled.span`
  padding-bottom: 20px;
  border-bottom: 10px solid #d9d9d9;
`;
const SubHeading = styled.div`
  margin-top: 6.2rem;
  margin-bottom: 6rem;
  line-height: 2.5rem;
  word-wrap: break-word;
  padding: 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 200;
`;

export default ServiceHeader;
