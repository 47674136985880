import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

import Footer from "../components/common/Footer";
import ServiceHeader from "../components/pages/Service/ServiceHeader";
import {
  Article,
  RevArticle,
  RevBoderArticle,
} from "../components/pages/Service/Article";
import {
  WhiteArticle,
  WhiteContainer,
  WhiteRevArticle,
} from "../components/pages/Service/WhiteSection";
import Helmet from "react-helmet";

const Service = () => {
  return (
    <Wrapper
      initial={{opacity: 0, y:50}}
      animate={{opacity: 1, y: 0}}
      transition={{duration: 1}}
      exit={{opacity: 0, y:50}}
    >
      <Helmet>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;700&display=swap');
          @import
          url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&family=Noto+Sans:wght@400;600;700&display=swap');
        </style>
      </Helmet>

      <ServiceHeader />

      <Article
        bg_img={
          <StaticImage
            src="../images/service/blueprint.jpg"
            alt="モノづくり"
            layout="fullWidth"
            style={{ height: "100%", maxHeight: "100%" }}
          />
        }
        
        heading="モノづくり"
        content="インドに現地法人を設立し、建築工事の設計・施工管理や品質調査、太陽光パネルの取付工事などの建設業を行うと共に、必要な建材を自社工場で現地生産しています。"
        heading2="【特徴1】 日本技術者による技術指導"
        content2="日本で30年以上の経験を持つ技術者と共に、設計・製造・施工を行っています。"
        heading3="【特徴2】 多角的なソリューション提供"
        content3="国内外から材料の調達、製品の計画・設計から製品化・納品までの一括管理を強みとし、お客様のニーズや課題に合わせた多角的なソリューションを提供します。"
        heading4="【特徴3】 遠隔指導の導入"
        content4="新型コロナにより海外渡航が制限されたため、日本とインドをつないだ新しい技術指導方法、製造現場・建設現場の管理方法を習得。遠く離れた現場でも、顧客ニーズに合わせたサポートを行っています。"
      />

      <RevArticle
        bg_img={
          <StaticImage
            src="../images/service/education.png"
            alt="ヒトづくり"
            layout="fullWidth"
            style={{ height: "100%", maxHeight: "100%" }}
          />
        }
       
        heading="ヒトづくり"
        content={"製造業、建設業における日本の高い技術力を世界に展開すべく、様々な技術指導プログラムを実施しています。\n 2022年初旬には、日本政府サポートの下、日本とインドの工場をつなぎ360度カメラとVRゴーグルを用いた遠隔技術指導研修を実施しました。\n今後ますます拡大するインド現地の人材育成ニーズに応えるべく、2023年春、日本式モノづくり学校を開校予定です。"}
      />

      <Article
        bg_img={
          <StaticImage
            src="../images/service/construction.jpg"
            alt="career header image"
            layout="fullWidth"
            style={{ height: "70%", maxHeight: "70%" }}
          />
        }
       
        heading="海外建設プロジェクト
        リモート技術支援（設計・調査）"
        content="25年以上、インドで実業を続ける中で培ってきた経験・ノウハウ・ネットワークに加え、現地自社工場・建築現場を最大限に活用し、市場調査やテスト販売、展示会実施や現地パートナー開発、人材教育など、インド進出に必要なあらゆるサポートを行っています。"

      />


      <RevArticle
        bg_img={
          <StaticImage
            src="../images/service/import.webp"
            alt="career header image"
            layout="fullWidth"
            style={{ height: "70%", maxHeight: "70%" }}
          />
        }
       
        heading="建設資材の輸出入"
        content="建設資材の計画・設計から製品化・納品までのトータル管理力を活かし、国内・海外向けに、必要となる資材を、国内外から調達し納入しています。また、海外建築工事に向けて、遠隔システムを活用した、技術指導・品質検査を行っています。"
      />
      

      {/* </WhiteContainer> */}
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  background-color: #204e73;
  div {
    color: white;
  }
`;

export default Service;
